<template>
  <div>
    <v-card>
      <v-card-title>CONSOLIDADO SOLICITUDES PARA COMPRA</v-card-title>
      <v-card-text>
        <div class="d-flex justify-end py-1">
          <v-btn color="blue" dark @click="generarSolicitud()">
            Nueva solicitud
          </v-btn>
        </div>
        
        <v-alert
          border="left"
          outlined
          text
          :type="alert.tipo"
          v-if="alert.mostrar"
        >
          <v-row>
            <v-col>
              <h4>{{ alert.mensaje }}</h4>
            </v-col>
            <v-col class="text-right">
              <v-btn
                :color="alert.color_boton"
                elevation="2"
                small
                dark
                @click="cerrar_alert"
                >Cerrar</v-btn
              >
            </v-col>
          </v-row>
        </v-alert>
      </v-card-text>
    </v-card>
    <div class="card">
      <div class="card-header card-header-primary card-header-icon">
        <div :class="`card-icon ${color_icono}`">
          <i class="material-icons" v-show="icono">{{ icono }}</i>
        </div>
        <h4 class="card-title">
          <div class="row">
            <div class="col-md-6">
              {{ titulo }}
            </div>
          </div>
          <div class="row justify-center">
            <div class="col-md-2 px-1">
              <v-text-field placeholder="Número" v-model="numero" type="text" style="max-width:180px" />
            </div>
            <div class="col-md-3 px-1">
              <v-menu v-model="menu1" :close-on-content-click="false" :nudge-right="40" transition="scale-transition"
                offset-y min-width="290px">
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field v-model="inicio_formated" label="Fecha de Inicio" prepend-icon="mdi-calendar" readonly
                    v-bind="attrs" v-on="on"></v-text-field>
                </template>
                <v-date-picker v-model="inicio" @input="menu1 = false" locale="es-cl" prev-icon="chevron_left"
                  next-icon="chevron_right"></v-date-picker>
              </v-menu>
            </div>
            <div class="col-md-3 px-1">
              <v-menu v-model="menu2" :close-on-content-click="false" :nudge-right="40" transition="scale-transition"
                offset-y min-width="290px">
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field v-model="termino_formated" label="Fecha de término" prepend-icon="mdi-calendar" readonly
                    v-bind="attrs" v-on="on" :disabled="!inicio"></v-text-field>
                </template>
                <v-date-picker v-model="termino" @input="menu2 = false" locale="es-cl" prev-icon="chevron_left"
                  next-icon="chevron_right"></v-date-picker>
              </v-menu>
            </div>
            <div class="col-md-2 text-right">
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn class="mx-1 mt-2" fab dark small color="primary" v-bind="attrs" v-on="on"
                    @click="loadSolicitudesCompra()">
                    <v-icon mediu style="color:white">
                      mdi-magnify
                    </v-icon>
                  </v-btn>
                </template>
                <span>Buscar</span>
              </v-tooltip>
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn class="mx-1 mt-2" fab dark small color="warning" v-bind="attrs" v-on="on" @click="limpiar()">
                    <v-icon mediu style="color:white">
                      mdi-broom
                    </v-icon>
                  </v-btn>
                </template>
                <span>Limpiar Filtro</span>
              </v-tooltip>
              <!-- <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn class="mx-1 mt-2" fab dark small color="success" v-bind="attrs" v-on="on" @click="loadSolicitudesRetiro(null, true)">
                    <v-icon large style="color:white">
                      description
                    </v-icon>
                  </v-btn>
                </template>
                <span>Exportar Excel</span>
              </v-tooltip> -->
            </div>
          </div>

          <div class="row">
              <div class="col-md-4"></div>
              <div class="col-md-4" style="text-align:center">
                <p style="font-size:15px">
                  (pinchar en el botón limpiar
                  <v-btn style="width:26px;height:26px;cursor:default" fab dark small dense color="warning">
                    <v-icon large style="color:white;font-size:25px">
                      mdi-broom
                    </v-icon>
                  </v-btn>
                  para listar todas las solicitudes)
                </p>
              </div>
              <div class="col-md-4"></div>
          </div>
        </h4>
      </div>
      <div class="card-body">
        <spinner v-if="spinner"></spinner>
        <v-simple-table :height="table_height">
          <template v-slot:default>
            <thead>
              <tr>
                <th class="text-center">NRO</th>
                <th class="text-center">FECHA EMISIÓN</th>
                <th class="text-center">EMISOR</th>
                <th class="text-center">PDF EMITIDO</th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(item, index) in solicitudes"
                :key="index"
                class="text-center"
              >
                <td>{{ item.nro }}</td>
                <td class="text-no-wrap">{{ item.fecha | format_date_spanish }}</td>
                <td>{{ item.nombre_usuario_emisor }}</td>
                <td>
                  <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                      <a
                        class="ma-2"
                        @click="generarPDF(item.id)"
                        v-bind="attrs"
                        v-on="on"
                      >
                        <v-icon large color="red">
                          mdi-file-pdf-box
                        </v-icon>
                      </a>
                    </template>
                    <span>Generar PDF</span>
                  </v-tooltip>
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
        <div class="pagination-wrapper">
          <div class="pagination">
            <button v-bind:class="{ 'active': p.active, 'page-item': true }" :data-page="p.label" v-for="p in links"
              :key="p.label" :disabled="!p.url" @click="loadSolicitudesCompra(p)">
              <span v-if="p.label.includes('Anterior')">←</span>
              <span v-else-if="p.label.includes('Siguiente')">→</span>
              <span v-else>{{ p.label }}</span>
            </button>
          </div>
        </div>
      </div>
    </div>

    <v-alert v-if="empty_data" border="right" color="blue-grey" dark>
      No hay solicitudes de compra...
    </v-alert>

  </div>
</template>
<script>
import { mapMutations, mapState } from "vuex";
export default {
  data: () => ({
    table_height: 550,
    spinner: false,
    titulo: "SOLICITUDES PARA COMPRA DE MATERIALES",
    color_icono: "azul",
    icono: "list",
    solicitudes: [],
    empty_data: false,

    inicio: "",
    termino: "",
    numero: "",
    menu1: false,
    menu2: false,
    page: 1,
    per_page: 10,
    links: [],

    alert: {
      mostrar: false,
      tipo: "",
      color_boton: "",
      mensaje: "",
    },
  }),
  mounted() {
    this.loadSolicitudesCompra();
  },
  computed: {
    ...mapState([
      "loading",
      "base_url",
      "headers",
      "headers_file",
      "wellboat",
      "user",
      "storage_url",
    ]),
    inicio_formated() {
      return this.formatDate(this.inicio);
    },
    termino_formated() {
      return this.formatDate(this.termino);
    },
  },
  methods: {
    formatDate(date) {
      if (!date) return null;
      const [year, month, day] = date.split("-");
      return `${day}-${month}-${year}`;
    },
    cerrar_alert() {
      this.alert.mostrar = false;
      this.alert.tipo = "";
      this.alert.color_boton = "";
      this.alert.mensaje = "";
    },
    async loadSolicitudesCompra(link = null) {
      if (link) {
        //extraer page del link
        this.page = link.url.split("?page=")[1];
      }
      this.spinner = true;
      this.mostrarLoading("Cargando listado de solicitudes...");
      const url = `${this.base_url}solicitudes_compras`;
      await this.axios
        .get(url, {
            ...this.headers,
            params: {
              inicio: this.inicio,
              termino: this.termino,
              numero: this.numero,
              per_page: this.per_page,
              page: this.page
            }
        })
        .then((response) => {
          //this.solicitudes = response.data.data.filter(d=>this.user.areas_ids.includes(d.areas_id));
          this.solicitudes = response.data.data;
          this.links = response.data.links;
          response.data.data.length === 0 && (this.empty_data = true);
          this.ocultarLoading();
        })
        .catch((error) => {
          console.log(error);
        });
      this.spinner = false;
    },
    volver() {
      this.$router.push("solicitud_materiales");
    },
    async generarPDF(id) {
      this.spinner = true;
      const url = `${this.base_url}solicitudes_compras/pdf/${id}`;
      await this.axios
        .post(url, {}, this.headers)
        .then((response) => {
          if (response.status === 200) {
            window.open(`${this.storage_url}materiales/compras/${response.data.url_pdf}`);
          }
        }).catch(() => {
          this.alert.mostrar = true;
          this.alert.tipo = "error";
          this.alert.color_boton = "red";
          this.alert.mensaje = "Hubo un error al generar PDF";
        });
      this.spinner = false;
    },
    generarSolicitud() {
      this.$router.push('solicitud_compras_generar');
    },
    limpiar() {
      this.inicio = null;
      this.termino = null;
      this.numero = "";
      this.page = 1;
      this.loadSolicitudesCompra();
    },
    ...mapMutations(["mostrarLoading", "ocultarLoading"]),
  },
};
</script>
<style scoped>
th {
  background-color: #355f94 !important;
  color: #fff !important;
  border: 1px solid #fff;
}
/* Hace que el encabezado sea fijo */
.table-container {
  overflow-y: auto;
  /* Agrega una barra de desplazamiento vertical si es necesario */
}

.table-container thead {
  position: sticky;
  top: 0;
  z-index: 1;
  /* Asegura que el encabezado esté por encima del contenido desplazable */
}

.mx-1 {
  height: 40px;
  width: 40px;
}

.smaller-icon {
  font-size: 20px;
}



/* Estilos generales */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: Arial, sans-serif;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #f0f0f0;
}

/* Contenedor con barra desplazable */
.pagination-wrapper {
  overflow-x: auto;
  /* Habilitar desplazamiento horizontal */
  padding: 10px 0;
  width: 100%;
  max-width: 100%;
  /* Ajustar el ancho máximo si lo deseas */
}

/* Barra de Paginación (sin fondo) */
.pagination {
  display: flex;
  align-items: center;
  padding: 10px 15px;
  border-radius: 30px;
}

/* Estilo de los items de la paginación */
.page-item {
  text-decoration: none;
  color: #355f94;
  padding: 10px 15px;
  margin: 0 5px;
  border-radius: 8px;
  font-size: 16px;
  cursor: pointer;
  border: 2px solid #355f94;
  transition: background-color 0.3s, transform 0.3s;
  background-color: white;
}

/* Páginas activas */
.page-item.active,
.page-item.active span,
.page-item.active span:hover {
  background-color: #355f94;
  /* Color de fondo para el item activo */
  color: white;
  /* Texto blanco en el item activo */
  box-shadow: 0 0 8px rgba(53, 95, 148, 0.6);
  transform: scale(1.1);
}

.pagination button {
  display: flex;
  /* Usamos flexbox en el button */
  justify-content: center;
  /* Centrar horizontalmente */
  align-items: center;
  /* Centrar verticalmente */
  padding: 5px 10px;
  /* Espaciado interno del botón */
  border: 1px solid #355f94;
  /* Borde azul */
  background-color: white;
  /* Fondo blanco */
  color: #355f94;
  /* Color de texto azul */
  cursor: pointer;
  /* Cursor de puntero */
  border-radius: 5px;
  /* Bordes redondeados */
}

.pagination button span {
  color: #355f94;
  /* Color de la letra azul */
  font-size: 16px;
  /* Tamaño de la letra */
  font-weight: bold;
  /* Peso de la fuente */
  padding: 0;
  /* Sin padding extra */
  margin: 0;
  /* Sin márgenes extra */
}

/* Efecto al pasar el mouse sobre los items inactivos */
.page-item:hover:not(.active) {
  background-color: #e1eff8;
}

/* Flechas de navegación */
.previous,
.next {
  font-weight: bold;
  font-size: 18px;
}

/* Efecto en las flechas */
.previous:hover,
.next:hover {
  background-color: #e1eff8;
}
</style>
