var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-card',[_c('v-card-title',[_vm._v("CONSOLIDADO SOLICITUDES PARA COMPRA")]),_c('v-card-text',[_c('div',{staticClass:"d-flex justify-end py-1"},[_c('v-btn',{attrs:{"color":"blue","dark":""},on:{"click":function($event){return _vm.generarSolicitud()}}},[_vm._v(" Nueva solicitud ")])],1),(_vm.alert.mostrar)?_c('v-alert',{attrs:{"border":"left","outlined":"","text":"","type":_vm.alert.tipo}},[_c('v-row',[_c('v-col',[_c('h4',[_vm._v(_vm._s(_vm.alert.mensaje))])]),_c('v-col',{staticClass:"text-right"},[_c('v-btn',{attrs:{"color":_vm.alert.color_boton,"elevation":"2","small":"","dark":""},on:{"click":_vm.cerrar_alert}},[_vm._v("Cerrar")])],1)],1)],1):_vm._e()],1)],1),_c('div',{staticClass:"card"},[_c('div',{staticClass:"card-header card-header-primary card-header-icon"},[_c('div',{class:("card-icon " + _vm.color_icono)},[_c('i',{directives:[{name:"show",rawName:"v-show",value:(_vm.icono),expression:"icono"}],staticClass:"material-icons"},[_vm._v(_vm._s(_vm.icono))])]),_c('h4',{staticClass:"card-title"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-6"},[_vm._v(" "+_vm._s(_vm.titulo)+" ")])]),_c('div',{staticClass:"row justify-center"},[_c('div',{staticClass:"col-md-2 px-1"},[_c('v-text-field',{staticStyle:{"max-width":"180px"},attrs:{"placeholder":"Número","type":"text"},model:{value:(_vm.numero),callback:function ($$v) {_vm.numero=$$v},expression:"numero"}})],1),_c('div',{staticClass:"col-md-3 px-1"},[_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-right":40,"transition":"scale-transition","offset-y":"","min-width":"290px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"Fecha de Inicio","prepend-icon":"mdi-calendar","readonly":""},model:{value:(_vm.inicio_formated),callback:function ($$v) {_vm.inicio_formated=$$v},expression:"inicio_formated"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.menu1),callback:function ($$v) {_vm.menu1=$$v},expression:"menu1"}},[_c('v-date-picker',{attrs:{"locale":"es-cl","prev-icon":"chevron_left","next-icon":"chevron_right"},on:{"input":function($event){_vm.menu1 = false}},model:{value:(_vm.inicio),callback:function ($$v) {_vm.inicio=$$v},expression:"inicio"}})],1)],1),_c('div',{staticClass:"col-md-3 px-1"},[_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-right":40,"transition":"scale-transition","offset-y":"","min-width":"290px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"Fecha de término","prepend-icon":"mdi-calendar","readonly":"","disabled":!_vm.inicio},model:{value:(_vm.termino_formated),callback:function ($$v) {_vm.termino_formated=$$v},expression:"termino_formated"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.menu2),callback:function ($$v) {_vm.menu2=$$v},expression:"menu2"}},[_c('v-date-picker',{attrs:{"locale":"es-cl","prev-icon":"chevron_left","next-icon":"chevron_right"},on:{"input":function($event){_vm.menu2 = false}},model:{value:(_vm.termino),callback:function ($$v) {_vm.termino=$$v},expression:"termino"}})],1)],1),_c('div',{staticClass:"col-md-2 text-right"},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mx-1 mt-2",attrs:{"fab":"","dark":"","small":"","color":"primary"},on:{"click":function($event){return _vm.loadSolicitudesCompra()}}},'v-btn',attrs,false),on),[_c('v-icon',{staticStyle:{"color":"white"},attrs:{"mediu":""}},[_vm._v(" mdi-magnify ")])],1)]}}])},[_c('span',[_vm._v("Buscar")])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mx-1 mt-2",attrs:{"fab":"","dark":"","small":"","color":"warning"},on:{"click":function($event){return _vm.limpiar()}}},'v-btn',attrs,false),on),[_c('v-icon',{staticStyle:{"color":"white"},attrs:{"mediu":""}},[_vm._v(" mdi-broom ")])],1)]}}])},[_c('span',[_vm._v("Limpiar Filtro")])])],1)]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-4"}),_c('div',{staticClass:"col-md-4",staticStyle:{"text-align":"center"}},[_c('p',{staticStyle:{"font-size":"15px"}},[_vm._v(" (pinchar en el botón limpiar "),_c('v-btn',{staticStyle:{"width":"26px","height":"26px","cursor":"default"},attrs:{"fab":"","dark":"","small":"","dense":"","color":"warning"}},[_c('v-icon',{staticStyle:{"color":"white","font-size":"25px"},attrs:{"large":""}},[_vm._v(" mdi-broom ")])],1),_vm._v(" para listar todas las solicitudes) ")],1)]),_c('div',{staticClass:"col-md-4"})])])]),_c('div',{staticClass:"card-body"},[(_vm.spinner)?_c('spinner'):_vm._e(),_c('v-simple-table',{attrs:{"height":_vm.table_height},scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('thead',[_c('tr',[_c('th',{staticClass:"text-center"},[_vm._v("NRO")]),_c('th',{staticClass:"text-center"},[_vm._v("FECHA EMISIÓN")]),_c('th',{staticClass:"text-center"},[_vm._v("EMISOR")]),_c('th',{staticClass:"text-center"},[_vm._v("PDF EMITIDO")])])]),_c('tbody',_vm._l((_vm.solicitudes),function(item,index){return _c('tr',{key:index,staticClass:"text-center"},[_c('td',[_vm._v(_vm._s(item.nro))]),_c('td',{staticClass:"text-no-wrap"},[_vm._v(_vm._s(_vm._f("format_date_spanish")(item.fecha)))]),_c('td',[_vm._v(_vm._s(item.nombre_usuario_emisor))]),_c('td',[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('a',_vm._g(_vm._b({staticClass:"ma-2",on:{"click":function($event){return _vm.generarPDF(item.id)}}},'a',attrs,false),on),[_c('v-icon',{attrs:{"large":"","color":"red"}},[_vm._v(" mdi-file-pdf-box ")])],1)]}}],null,true)},[_c('span',[_vm._v("Generar PDF")])])],1)])}),0)]},proxy:true}])}),_c('div',{staticClass:"pagination-wrapper"},[_c('div',{staticClass:"pagination"},_vm._l((_vm.links),function(p){return _c('button',{key:p.label,class:{ 'active': p.active, 'page-item': true },attrs:{"data-page":p.label,"disabled":!p.url},on:{"click":function($event){return _vm.loadSolicitudesCompra(p)}}},[(p.label.includes('Anterior'))?_c('span',[_vm._v("←")]):(p.label.includes('Siguiente'))?_c('span',[_vm._v("→")]):_c('span',[_vm._v(_vm._s(p.label))])])}),0)])],1)]),(_vm.empty_data)?_c('v-alert',{attrs:{"border":"right","color":"blue-grey","dark":""}},[_vm._v(" No hay solicitudes de compra... ")]):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }